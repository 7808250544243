.fade-in {
    animation: fadeIn 0.5s;
}

.fade-out {
    animation: fadeOut 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.alert-z-index {
    z-index: 2050; /* Bootstrap modals have a z-index of 1040, so 1050 ensures it is on top */
}

.img-width {
    width: 40px;
    height: 40px;
}

.img-width-big {
    width: 80px;
    height: 80px;
}

.img-width img , .img-width-big img {
    width: 100%;
    height: 100%;
}